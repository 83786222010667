import React from "react";

const SectionMlodziez = () => {
  return (
    <section id="Mlodziez">
      <div className="flex flex-col  ">
        <div className="  text-center  p-6 md:py-12 md:px-[15%] xl:px-[30%] border-b-2 border-gray-100">
          <h2 className="mb-2 text-2xl font-bold">MŁODZIEŻ</h2>
          <p>
            Kiedy Profesor Philip Zimbardo poznał naszą działalność
            oraz&nbsp;specyfikę Nikiszowca, zdecydował o&nbsp;powierzeniu nam
            swojego nazwiska. Ideą Centrum jest stworzenie młodym ludziom
            przestrzeni - miejsca spotkań, dzięki czemu młodzi ludzie mogą
            tworzyć autentyczne relacje. Szczególny nacisk kładziemy
            na&nbsp;budowanie lokalnej tożsamości oraz poczucia przynależności.
            Dlatego Centrum to&nbsp;miejsce, gdzie młodzież angażuje się
            w&nbsp;działania na&nbsp;rzecz Nikiszowca oraz wolontariat poprzez
            udział w lokalnych wydarzeniach i&nbsp;inicjatywach takich jak:
            Jarmark na&nbsp;Nikiszu, Odpust u Babci Anny, Święto Sąsiada.
            Centrum Zimbardo działa od&nbsp;2014&nbsp;roku.
          </p>
        </div>
      </div>
    </section>
  );
};

export default SectionMlodziez;
