import React from "react";

const SectionSpolecznosc = () => {
  return (
    <section id="Spolecznosc">
      <div className="flex flex-col  ">
        <div className="  text-center  p-6 md:py-12 md:px-[15%] xl:px-[30%] border-b-2 border-gray-100">
          <h2 className="mb-2 text-2xl font-bold">SPOŁECZNOŚĆ LOKALNA</h2>
          <p>
            Chociaż Centrum jest adresowane przede wszystkim do młodych ludzi,
            to&nbsp;równocześnie jest to miejsce spotkań społeczności. Przyjść
            może tu każdy - bez względu na&nbsp;wiek, status społeczny,
            materialny, wyznanie, poglądy, czy narodowość. Centrum cechuje
            bowiem otwartość i&nbsp;szacunek do różnorodności
            z&nbsp;równoczesnym przywiązaniem do&nbsp;lokalnej tradycji.
            Realizujemy w&nbsp;Centrum rożne inicjatywy kulturalne
            i&nbsp;społeczne takie jak: warsztaty, spotkania z&nbsp;ciekawymi
            ludźmi, pokazy filmowe, mini-festiwale i&nbsp;koncerty, tematyczne
            spotkania z&nbsp;mieszkańcami, konsultacje&nbsp;i&nbsp;dyskusje.
          </p>
        </div>
      </div>
    </section>
  );
};

export default SectionSpolecznosc;
