import React, { useRef } from "react";
import filImage from "../../images/fil.png";
import { SocialIcon } from "react-social-icons";
import { motion } from "framer-motion";

const SectionFil = (scrollRef: any) => {
  console.log(scrollRef);
  return (
    <section id="Section FIL">
      <motion.div
        className="p-6 md:p-12 xl:pr-52 xl:pl-52 text-lg flex flex-col items-center justify-center "
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ root: scrollRef }}
        transition={{ delay: 0.1 }}
      >
        <img src={filImage} alt="filImage" width={250} />
        <p className="mb-12">
          Centrum Zimbardo jest prowadzone przez Stowarzyszenie Fabryka
          Inicjatyw Lokalnych.
        </p>
        <div className="mb-12">
          <SocialIcon url="https://www.facebook.com/StowarzyszenieFIL/" />
        </div>
      </motion.div>
    </section>
  );
};

export default SectionFil;
