import React from "react";
import filoveLogo from "../../images/filoveLogo.png";
import centrumLogo from "../../images/centrumLogo.png";
import { SocialIcon } from "react-social-icons";
import { motion } from "framer-motion";

const Footer = (scrollRef: any) => {
  return (
    <section id="Footer">
      <motion.div
        className=" bg-gray-100    text-center p-6 text-xs md:text-lg"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ root: scrollRef }}
        transition={{ delay: 0.1 }}
      >
        <h2 className="text-2xl md:text-3xl">KONTAKT</h2>
        <div className="flex lg:flex-row lg:items-center justify-evenly lg:justify-around mt-6 md:mt-12 flex-col ">
          <div className="flex mb-12 ">
            <div className="flex items-center justify-center mx-2 ">
              <img src={centrumLogo} alt="centrumLogo" width={50} height={50} />
            </div>
            <div className="mx-2">
              <ul className=" text-left">
                <li className="text-lg">Centrum Zimbardo</li>
                <li>
                  <a
                    href="mailto:a.solinska@fil.org.pl"
                    className=" text-blue-600"
                  >
                    a.solinska@fil.org.pl
                  </a>
                </li>
                <li>
                  <a href="tel:+48795363594">+48 795 363 594</a>
                </li>
                <li>
                  nr konta: (PLN) <br />
                  21&nbsp;8437&nbsp;0002&nbsp;0050&nbsp;0544&nbsp;3815&nbsp;0006
                </li>
              </ul>
            </div>
            <div className="flex items-center mx-2  ml-auto">
              <SocialIcon url="https://www.facebook.com/CentrumZimbardowNikiszowcu" />
            </div>
          </div>
          {/* Section FILOWE  */}
          <div className="flex mb-12 ">
            <div className=" flex items-center justify-center mx-2">
              <img src={filoveLogo} alt="filoveLogo" width={50} height={50} />
            </div>
            <div className="mx-2">
              <ul className=" text-left">
                <li className="text-lg">FILove - sklep</li>
                <li>
                  <a href="mailto:filove@fil.org.pl" className=" text-blue-600">
                    filove@fil.org.pl
                  </a>
                </li>
                <li>
                  <a href="tel:+48505260183">+48 505 260 183</a>
                </li>
              </ul>
            </div>
            <div className="flex items-center mx-2 ml-auto">
              <SocialIcon url="https://www.facebook.com/fabrykainicjatywlokalnych" />
            </div>
          </div>
        </div>
      </motion.div>
    </section>
  );
};

export default Footer;
