import React from "react";

const SectionTurystyka = () => {
  return (
    <section id="Turystyka">
      <div className="flex flex-col  ">
        <div className="  text-center  p-6 md:py-12 md:px-[15%] xl:px-[30%] border-b-2 border-gray-100">
          <h2 className="mb-2 text-2xl font-bold">
            TURYSTYKA I PAMIĄTKI LOKALNE
          </h2>
          <p>
            Centrum Zimbardo to&nbsp;także miejsce, w&nbsp;którym turyści mogą
            dowiedzieć się więcej o&nbsp;Nikiszowcu i&nbsp;jego historii - tej
            najnowszej i&nbsp;starszej oraz uzyskać informacje nt. osiedla.
            Tutaj można także zamówić wycieczkę z&nbsp;lokalnym przewodnikiem w
            ramach prowadzonej przez nas sieci lokalnych przewodników.
            W&nbsp;Centrum Zimbardo działa także sklep z&nbsp;lokalnymi
            pamiątkami&nbsp;-&nbsp;FILove.
          </p>
        </div>
      </div>
    </section>
  );
};

export default SectionTurystyka;
