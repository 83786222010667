import React from "react";
import barImage from "../../images/bar.jpg";
import logoImage from "../../images/logo.png";
import { motion } from "framer-motion";

const Bar = (scrollRef: any) => {
  return (
    <section id="Bar">
      <motion.div
        className="flex my-12 items-center justify-center"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ root: scrollRef }}
        transition={{ delay: 0.1 }}
      >
        <div>
          <img src={barImage} alt="bar" />
        </div>
      </motion.div>
    </section>
  );
};

export default Bar;
