import React from "react";
import hero from "../../images/hero.jpg";
import centrumImage from "../../images/centrumLogo.png";
import { motion } from "framer-motion";

const Hero = (scrollRef: any) => {
  return (
    <section id="Hero">
      <motion.div
        className="flex flex-col items-center"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ root: scrollRef }}
        transition={{ delay: 0.1 }}
      >
        <div className="flex justify-center">
          <img
            src={centrumImage}
            alt="centrumImage"
            className="md:h-[8rem] h-[6rem]"
          />
        </div>
        <div className="md:my-12 my-6 w-[90%] lg:w-[60%]">
          <img
            src={hero}
            alt="hero"
            className=" h-[5%] w-full object-cover object-center"
          />
        </div>
      </motion.div>
    </section>
  );
};

export default Hero;
