import Footer from "../../components/Footer";
import Bar from "../../components/Bar";
import Hero from "../../components/Hero";
import SectionFil from "../../components/SectionFil";
import { useRef } from "react";
import SectionMlodziez from "../../components/SectionMlodziez";
import SectionTurystyka from "../../components/SectionTurystyka";
import SectionSpolecznosc from "../../components/SectionSpolecznosc";

const Homepage = () => {
  const scrollRef = useRef(null);

  return (
    <div className="Home" ref={scrollRef}>
      <Bar scrollRef={scrollRef} />
      <Hero scrollRef={scrollRef} />
      <SectionMlodziez />
      <SectionSpolecznosc />
      <SectionTurystyka />
      <SectionFil scrollRef={scrollRef} />
      <Bar scrollRef={scrollRef} />
      <Footer scrollRef={scrollRef} />
    </div>
  );
};

export default Homepage;
